<script>
export default {
  components: {},
}
</script>

<template>
  <div>
    <slot />
  </div>
</template>
