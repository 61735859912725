<template>
  <div class="LoginForm">
    <b-form>
      <div v-if="invalid" class="LoginForm__api-error">
        ドメインもしくはメールもしくはパスワードが間違っています。
      </div>
      <div class="LoginForm__label">ログイン</div>
      <b-form-group id="input-group-1" label="ドメイン" label-for="input-1" class="required">
        <b-input-group>
          <b-form-input
            id="input-1"
            type="text"
            :class="status($v.form.domain)"
            :formatter="formatter"
            @change="setFormValue('domain', $event)"
          />
          <b-input-group-prepend v-if="!!errorMessages.form.domain" class="icon-alert">
            <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
          </b-input-group-prepend>
        </b-input-group>
        <span v-if="!!errorMessages.form.domain" class="label-error"
          >{{ errorMessages.form.domain }}
        </span>
      </b-form-group>

      <b-form-group id="input-group-1" label="メールアドレス" label-for="input-2" class="required">
        <b-input-group>
          <b-form-input
            id="input-2"
            type="email"
            :class="status($v.form.username)"
            :formatter="formatter"
            @change="setFormValue('username', $event)"
          />
          <b-input-group-append v-if="!!errorMessages.form.username" class="icon-alert">
            <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
          </b-input-group-append>
        </b-input-group>
        <span v-if="!!errorMessages.form.username" class="label-error"
          >{{ errorMessages.form.username }}
        </span>
      </b-form-group>

      <b-form-group id="input-group-1" label="パスワード" label-for="input-3" class="required">
        <b-input-group>
          <b-form-input
            id="input-3"
            type="password"
            :class="status($v.form.password)"
            autocomplete="new-password"
            :formatter="formatter"
            @change="setFormValue('password', $event)"
          />
          <b-input-group-append v-if="!!errorMessages.form.password" class="icon-alert">
            <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
          </b-input-group-append>
        </b-input-group>
        <span v-if="!!errorMessages.form.password" class="label-error"
          >{{ errorMessages.form.password }}
        </span>
      </b-form-group>

      <b-button
        class="LoginForm__button"
        type="submit"
        variant="primary"
        @click.prevent="onSubmit(form)"
      >
        <b-spinner v-if="loading" small class="loading-spin" />
        <span>ログイン</span>
      </b-button>
    </b-form>
  </div>
</template>

<script>
import { required, email, helpers } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import { MESSAGES } from '@/utils/messages'
import { MAX_LENGTH } from '@/utils/constants'

export default {
  data() {
    return {
      form: {
        domain: '',
        username: '',
        password: '',
      },
      errorMessages: {
        form: {
          domain: '',
          username: '',
          password: '',
        },
      },
      invalid: false,
      loading: false,
    }
  },
  validations: {
    form: {
      domain: {
        required,
        validDomain: (value) => value.endsWith('.com') || value.endsWith('.co.jp'),
      },
      username: {
        required,
        email,
      },
      password: {
        required,
        // at least: 8 chars, 1 uppercase, 1 lowercase, 1 number and 1 special char
        passwordPolicy: helpers.regex(
          'passwordPolicy',
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
        ),
      },
    },
  },
  computed: {},
  methods: {
    ...mapActions('user', ['login']),
    async onSubmit(form) {
      if (!this.isValidForm()) {
        return
      }
      this.loading = true
      const { username, password, domain } = form
      if (username && password) {
        const result = await this.login({
          domain,
          email: username,
          password,
        })
        if (!result) {
          this.invalid = true
        }
        this.loading = false
      }
    },
    setFormValue(name, value) {
      if (value === undefined) {
        return
      }
      this.form[name] = value
      const validateInstance = this.$v.form[name]
      validateInstance.$touch()
      this.setValidateErrorMessage(validateInstance, name)
    },
    setValidateErrorMessage(instance, name) {
      // check required
      if (!instance.required) {
        this.errorMessages.form[name] = MESSAGES.COMMON.MSG08
        return
      } else {
        this.errorMessages.form[name] = ''
      }
      // check domain
      this.checkDomain(instance, name)
      // check password
      this.checkPassWord(instance, name)
      // check email
      this.checkEmail(instance, name)
    },
    status(validation) {
      return {
        invalid: validation.$dirty && validation.$invalid,
      }
    },
    isValidForm() {
      this.$v.form.$touch()
      const isValid = !this.$v.form.$invalid
      if (!isValid) {
        Object.keys(this.form).forEach((key) => {
          this.setValidateErrorMessage(this.$v.form[key], key)
        })
      }
      return isValid
    },
    formatter(e) {
      return String(e).substring(0, MAX_LENGTH.DEFAULT)
    },
    checkEmail(instance, name) {
      if (name === 'username') {
        if (!instance.email) {
          this.errorMessages.form[name] = MESSAGES.COMMON.MSG09
        }
      } else {
        if (!this.errorMessages.form[name]) {
          this.errorMessages.form[name] = ''
        }
      }
    },

    checkDomain(instance, name) {
      if (name === 'domain' && !instance.validDomain) {
        this.errorMessages.form[name] = '登録情報を確認してください。'
      } else {
        if (!this.errorMessages.form[name]) {
          this.errorMessages.form[name] = ''
        }
      }
    },

    checkPassWord(instance, name) {
      if (name === 'password' && !instance.passwordPolicy) {
        this.errorMessages.form[name] = MESSAGES.COMMON.MSG07
      } else {
        if (!this.errorMessages.form[name]) {
          this.errorMessages.form[name] = ''
        }
      }
    },
  },
}
</script>
