<template>
  <layout-login>
    <div class="LoginPage">
      <div class="LoginPage__left-items">
        <div class="welcome">SaaS一元管理システム</div>
      </div>
      <div class="LoginPage__right-items">
        <div class="content">
          <div class="logo-box">
            <img class="logo-mt" alt="logo-mt" src="@/assets/images/login/MT.png" />
          </div>
          <div class="login-form">
            <login-form />
          </div>
        </div>
      </div>
    </div>
  </layout-login>
</template>

<script>
import LayoutLogin from '../../layouts/main/login'
import LoginForm from '@/components/forms/login-form'

/**
 * LoginPage component
 */
export default {
  name: 'LoginPage',
  components: {
    LayoutLogin,
    LoginForm,
  },
}
</script>
